"use client";
import React, { useState } from "react";
import { HoveredLink, Menu, MenuItem, } from "../ui/navbar-menu";
import { cn } from "@/lib/utils";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "@/GlobalRedux/UserSlice";
import { useRouter } from "next/navigation";
const Logo = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575815/nubitlogo_uu0waa.png'

export function NavbarDemo() {
  return (
    <div className="relative w-full  text-white flex items-center justify-center">
      <Navbar className="top-2 "  />
    </div>
  );
}

function Navbar({
  className,
}) {
  const [active, setActive] = useState(null);
  const pathName = usePathname()
  const dispatch = useDispatch()
  const router = useRouter()
  const { users } = useSelector((state) => state.user);
  // logout function if sale use is login 
  const LogoutUser = () => {
    dispatch(Logout())
    router.push('/login')
  }
 

  return (
    (<div
      className={cn("fixed top-10 inset-x-0  max-w-2xl text-white  mx-auto z-50", className)}>
      <Menu setActive={setActive} className="text-white">
        <div className="flex items-center justify-center">
          <Link href={'/'}>
            <Image
              alt="Nubit Login - Secure Access to Your Account"
              className={cn("h-full w-full object-fit scale-[1.15]")}
              width="60"
              height="80"
              // priority
              src={Logo} />
          </Link>
        </div>
        <div
          className={`${users?.email === "sales@gmail.com" ? "flex  h-full  items-center justify-between" : " flex  h-full   items-center justify-between"} w-72 sm:w-96`}
        >
        
          <MenuItem navigation="/about" setActive={setActive} active={active}
            className={`${pathName === '/about' ? 'text-blue-400 underline  fontbold translate-x-1' : ''}`} item="About">
              <div className="flex flex-col gap-3 ">
              <HoveredLink href="/portfolio">Portfolio </HoveredLink>
              <Link href={"/#projects"}>
              <span  className="text-neutral-700 font-bold  hover:border-b-2 hover:text-black">Projects </span>
              </Link>
              </div>
              <HoveredLink href="/blogs" className='flex sm:hidden'>Blog</HoveredLink>
              <HoveredLink href="/AddReviews" className='flex sm:hidden'>AddReviews</HoveredLink>
            
          </MenuItem>
          <MenuItem
            className={`${pathName === '/blogs' ? 'text-blue-400 underline fontbold translate-x-1' : ''} hidden sm:block`}
            navigation="/blogs" setActive={setActive} active={active} item="Blogs">
                <HoveredLink
                href="/AddReviews"
                //  className={`${pathName==='/careerform'?'text-blue-400 underline fontbold translate-x-1':''}`}
                className={users?.data.role === "Admin" ? "block  " : " hidden"}
              >
                AddReviews
              </HoveredLink>
          </MenuItem>
          <MenuItem navigation="/contact" setActive={setActive} active={active} item="Contact">
            <div className="flex flex-col space-y-4 text-sm ">
              <HoveredLink href="/contact"
                className={`${pathName === '/contact' ? 'text-blue-400 underline fontbold translate-x-1' : ''}`}
              >Contact Us</HoveredLink>
              <HoveredLink href="/careerform"
                className={`${pathName === '/careerform' ? 'text-blue-400 underline fontbold translate-x-1' : ''}`}
              >Career Form</HoveredLink>
              <HoveredLink
                href="/showcareerform"
                //  className={`${pathName==='/careerform'?'text-blue-400 underline fontbold translate-x-1':''}`}
                className={users?.data.role === "Admin" ? "block  " : " hidden"}
              >
                Show Career Form
              </HoveredLink>
            </div>
          </MenuItem>
          <MenuItem navigation="/#services"  setActive={setActive} active={active} item="Services">
          <div className="flex flex-col space-y-4 text-sm">
            <HoveredLink   href="/#services">Web Development</HoveredLink>
            <HoveredLink  href="/#services">Mobile Application</HoveredLink>
            <HoveredLink  href="/#services">UI/UX Design</HoveredLink>
            <HoveredLink  href="/#services">Business Processing
            Outsourcing </HoveredLink>
          </div>
        </MenuItem>

          {
           users?.data.role === "Admin" ? (
              <span onClick={LogoutUser} 
              className="cursor-pointer  text-white bg-[#135C95] text-sm md:text-sm border border-black rounded-md p-1  md:font-bold hover:opacity-[0.9]"
              >
                Logout
              </span>
            ) : (
              <span onClick={()=>{router.push('/login')}} 
              className="cursor-pointer  text-white bg-[#135C95] text-sm md:text-sm border border-black rounded-md p-1  md:font-bold hover:opacity-[0.9]"
              >
                Login
              </span>
            )
          }
          {/* <MenuItem navigation="/login" setActive={setActive} active={active} 
             className={users === "sales@gmail.com" ? "hidden" : "block"} 
            // className={users == "sales@gmail.com" ? "hidden" :"block"}
             item="Login" ></MenuItem> */}

          {/* <span
            className={users?.email === "sales@gmail.com" ? "block  cursor-pointer text-white text-sm md:text-lg md:font-bold hover:opacity-[0.9] dark:text-white" : " hidden"}
            onClick={LogoutUser}
          >
            Logout
          </span> */}
        </div>
      </Menu>
    </div>)
  );
}
