"use client"
import React from "react";
import { BackgroundLines } from "@/components/ui/background-lines";
import Link from "next/link";
import { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
export function BgLines() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true }); 

  return (
    <BackgroundLines className="flex items-center justify-center   w-full flex-col px-4">
       <motion.div
      ref={ref}
      initial={{ opacity: 0, x: -100 }} 
      animate={isInView ? { opacity: 1, x: 0 } : {}} 
      transition={{ duration: 1, delay: 0.2 }}  
      className="flex items-center justify-center h-[600px] w-full flex-col "
    >
      <h2
        className="bg-clip-text max-w-6xl text-transparent text-center bg-gradient-to-b from-neutral-600 to-white text-2xl md:text-4xl lg:text-6xl font-sans py-8 md:py-16 relative z-20 font-bold tracking-tight">
        We are team of positive and collaborative optimists
      </h2>
      <button className="p-[3px] relative ">
        <div className="absolute inset-0  bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg" />
          <Link href={'https://wa.link/ppbmjw'}>
        <div className="px-8 py-2  bg-black rounded-[6px]  relative group transition duration-200 text-white hover:bg-transparent">
          Get In Touch
        </div>
          </Link>
      </button>
    </motion.div>
     
    </BackgroundLines>
  );
}
