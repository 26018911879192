"use client";
import React from "react";

const BlogModal = ({ openModal, onClose,  data }) => {
  return (
    <>
            {/* Trigger Button */}
            
      
            {/* Modal */}
            {openModal && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white rounded-lg shadow-lg w-full max-w-2xl">
                  <div className="px-4  text-lg font-semibold text-white bg-[#135C95] rounded-t-lg">
                  <h2 className="mb-4 py-4 text-xl font-bold text-white">
                      {data.title}
                    </h2>
                  </div>
      
                  {/* Modal Body */}
                  <div className="p-6 overflow-y-auto max-h-[60vh] text-gray-800">
                    
                    <p className="mb-4">
                      {data.message}
                    </p>
                   
                  </div>
      
                  {/* Modal Footer */}
                  <div className="flex items-center justify-end gap-4 p-4 border-t">
                    <button
                      className="px-4 py-2 text-gray-100 bg-gray-500 rounded-md hover:bg-gray-300 hover:text-black"
                      onClick={onClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
    </>
  );
};

export default BlogModal;
