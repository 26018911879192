"use client"
import { HoverEffect } from "@/components/ui/card-hover-effect";
import { motion, useInView } from "framer-motion";
import Link from "next/link";
// import React, { useRef,  } from "react";
import { useSelector } from "react-redux";

export function Blogs() {
  const { users } = useSelector((state) => state.user);
  // const ref = useRef(null);
  // const isInView = useInView(ref, { once: true });
  return (
    (<div
      className="h-full w-full rounded-md flex items-center justify-center   bg-grid-white/[0.02] ">
      <div className=" p-4 max-w-7xl   relative z-10  w-full pt-20 md:pt-0">
        <motion.h1
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0 }}
          className="text-4xl md:text-7xl font-bold text-center py-5 bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 bg-opacity-50">
          Innovating the Future Today.
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0 }}
          className="mt-4 font-normal text-base text-neutral-300 max-w-lg text-center mx-auto">
          At the forefront of technological breakthroughs, our blog explores the latest trends, innovations, and insights in the software industry. Stay informed and ahead of the curve as we dive into everything from cloud computing to AI advancements. Whether you&apos;re a tech enthusiast or an industry professional, there&apos;s something for everyone. Let&apos;s build the future together.
          <span className={users?.data.role === "Admin" ? "block" : " hidden"}>
            <Link href={'/Addblogs'}>
              <button className="shadow-[0_0_0_3px_#000000_inset] px-6 py-2 my-8 bg-transparent border border-white text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">
                Add Blogs
              </button>
          </Link>
          </span>
        </motion.p>

        <motion.div
         className="flex justify-center items-center w-full h-full mx-auto text-center "
        >
          <HoverEffect />
        </motion.div>
      </div>
    </div>)
  );
}
