import axios from "axios";
// const URL = "https://nubit-soft.vercel.app";
const URL = "https://nubitsoft-backend.vercel.app";


export const AddReviewOrCareerForm = async (route, data, token) => {
  const config = {
    url: URL + route,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,

    },
    data: data,
  };
  try {
    const res = await axios.request(config);
    return res.data

  } catch(err) {
    return err.response.data
  }
}

export const AddNewBlog = async (route, data,token) => {
  const config = {
    url: URL + route,
    method: "POST",
    headers: {
     "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: JSON.stringify(data),
  };
  try {
    const res = await axios.request(config);
    return res.data

  } catch(err) {
    return err.response.data
  }
}

export const getAllBlogs = async (route) => {
  const config = {
  url: URL + route,
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  }
};
try {
  const res = await axios.request(config);
  return res.data
} catch(err) {
  return err.response.data
}
};


export const EditReviewOrBlog = async (route, token, data) => { // Accept data as an argument
  const config = {
    url: URL + route, 
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    data: JSON.stringify(data)
  };

  try {
    const res = await axios.request(config);
    return res.data;
  } catch (err) {
    return err.response ? err.response.data : { error: "Network error" };
  }
};

export const DeleteBlog = async (route,token) => {
  const config = {
  url: URL + route,
  method: "DELETE",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,

  }
};
try {
  const res = await axios.request(config);
  return res.data
} catch(err) {
  return err.response.data
}
};
export const DeleteApplicant = async (route) => {
  const config = {
  // url: URL + route,
  url:  route,
  method: "DELETE",
  headers: {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${token}`,

  }
};
try {
  const res = await axios.request(config);
  return res.data
} catch(err) {
  return err.response.data
}
};
