"use client";
import React, { useRef, useState } from "react";
import { Label } from "../ui/label";
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage,Field } from 'formik';
import Fields from "./Fields";
import {CareerFormFields} from './InputData'
import { AddReviewOrCareerForm } from '@/API/response';
import { ToastContainer, toast } from 'react-toastify';
import { errorNotify, successNotify } from "@/hooks/toast";

export function CareerForm() {
  const [loading, setLoading] = useState(false);
  const warningNotify = (message) => toast.warning(message, { autoClose: 2000, });
  const file = useRef();
  const route = '/career/add'
  const handleSubmit = async(values,{resetForm}) => {
    const data = new FormData()
    data.append("fullname",values.fullname)
    data.append("email",values.email)
    data.append("address",values.address)
    data.append("mobile",values.mobile)
    data.append("city",values.city)
    data.append("department",values.department)
    data.append("experience",values.experience)
    data.append("previousJob",values.previousJob)
    data.append("linkedin",values.linkedin)
    data.append("foundJobFrom",values.foundJobFrom)
    data.append("file",file.current.files[0])
    try {
      setLoading(true)
      const response = await AddReviewOrCareerForm(route,data)
      setLoading(false)
  
      if (file.current) {
        file.current.value = ''; 
      }
      if(response.message=="Form Submit Successfully"){
        successNotify("Form Add Successful!")
        resetForm()

      }
      else if(response.message=="Email Already Exist"){
        warningNotify("Email Already Exist")
      }
    } catch (error) {
      setLoading(false)
      console.log(error.response.data)
      errorNotify(error.response.data)
    }
  };
  const validationSchema = Yup.object().shape({
    fullname: Yup.string()
      .required('Full Name is required')
      .min(3, 'Full Name must be at least 3 characters'),
    address: Yup.string().required('Address is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    mobile: Yup.string()
      .min(10, 'Mobile number atleast in 10 digits')
      .required('Mobile number is required'),
    city: Yup.string().required('City is required'),
    department: Yup.string().required('Department is required'), 
    experience: Yup.string()
      .min(0, 'Experience cannot be negative')
      .required('Experience is required'),
    linkedin: Yup.string()
      .url('Invalid LinkedIn URL') 
      .required('LinkedIn URL is required'), 
    foundJobFrom: Yup.string().required('Please select how you found the job'),
    
  });
  const initialValues = {
    fullname: '',
    address: '',
    email: '',
    mobile: '',
    city: '',
    department: '',
    experience: '',
    previousJob: '',
    linkedin: '',
    foundJobFrom: '',
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
   
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 5) {
        warningNotify("File size exceeds 5 MB. Please upload a smaller file.")
        event.target.value = null;
      } else {
        console.log("File is within the size limit.");
      }
    }
  };
  return (
    <div
      className="max-w-xl w-full mx-auto rounded-none md:rounded-2xl md:py-4 shadow-input  ">
      <h2 className="font-bold text-4xl py-5 text-neutral-200">
       Career Form
      </h2>
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
           
          <Form className="space-y-6">
        {
        CareerFormFields.map((section,sectionIndex)=>(
          <div key={sectionIndex} className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {
              section.fields.map((field,fieldIndex)=>(
                <div key={fieldIndex}>
                <Label htmlFor={field.id} className="py-2"> {field.label}</Label>
              <Fields 
              name={field.id}  
              type={field.type}     
              placeholder={field.placeholder}
              options={field.type === 'select' ? field.options : undefined} 
              />
              <ErrorMessage name={field.id} component="div" className="text-red-600 text-sm mt-1" />
              </div>
              ))
            }
            
          </div>
        ))
        }
            <input type="file"  onChange={handleFileChange} ref={file} />
        <button
            className="bg-gradient-to-br relative group/btn  from-zinc-900 to-zinc-900  block bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
            disabled={loading}
            type="submit"
            >
            {loading ? (
          <span className="loader">Loading....</span>
        ) : (
          <>
            Submit &rarr;
            <BottomGradient />
          </>
        )}
          </button>
          </Form>

        </Formik>
        <ToastContainer/>
        <div
          className="bg-gradient-to-r from-transparent via-neutral-700 to-transparent my-8 h-[1px] w-full" />
        </div>
  );
}

const BottomGradient = () => {
  return (<>
    <span
      className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
    <span
      className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
  </>);
};