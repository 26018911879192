"use client";
import { useRef } from "react";
import { DirectionAwareHover } from "../ui/direction-aware-hover";
import { useInView,motion } from "framer-motion";

export function HoverCard({imageUrl, post, fullName, blurURL}) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <motion.div 
    ref={ref}
      initial={{ opacity: 0, x: -100 }} 
      animate={isInView ? { opacity: 1, x: 0 } : {}} 
      transition={{ duration: 1, delay: 0.2 }}
    className="h-full py-8 lg:py-32  object-fill relative flex items-center justify-center">
      <DirectionAwareHover  imageUrl={imageUrl} blurURL={blurURL}>
        <p className="font-bold text-xl">{post}</p>
        <p className="font-normal text-sm">{fullName}</p>
      </DirectionAwareHover>
    </motion.div>
  );
}
