"use client"
import React, { useEffect, useState } from "react";

export  function BlogEditModal({ isOpen, onClose, data ,onSubmit}) {
  const [formData, setFormData] = useState({
    name: "",
    title: "",
    message: "",
  });
  useEffect(() => {
    if (data) {
      setFormData({
        name: data.name || "",
        title: data.title || "",
        message: data.message || "",
      });
    }
  }, [data]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    // console.log("Form data: -->>>", formData);
    onClose()
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 opacity-9 text-black bg-opacity-50 pt-10 backdrop-blur-sm flex justify-center items-center z-50">
      <div className="bg-black text-black p-8 rounded-lg max-w-lg w-full relative">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-200 font-bold text-4xl hover:text-red-400"
        >
          × 
        </button>
        <h2 className="text-2xl font-semibold mb-4 text-gray-300 text-center w-full">Edit Blog Form </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300"> Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="mt-1 block w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">Blog Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className="mt-1 block w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>
         
          <div>
            <label className="block text-sm font-medium text-gray-300">Description</label>
            <textarea
              name="message"
              rows={6}
              value={formData.message}
              onChange={handleInputChange}
              className="mt-1 block w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>
       
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none"
          >
            Update Blogs
          </button>
        </form>
      </div>
    </div>
  );
}
