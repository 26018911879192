"use client";
import { Meteors } from "../ui/meteors";
import Image from "next/image";

 function ServicesCard({title,url,blurURL,otherTitle}) {
  return (
    <div className=" w-full max-h-[500px]  relative max-w-lg  transition-transform duration-300 ease-in-out hover:scale-105 hover:cursor-pointer">
      <div className="absolute inset-0 h-full w-full bg-gradient-to-r from-blue-500 to-teal-500 transform scale-[0.80] bg-red-500 rounded-full blur-3xl" />
      <div className="relative shadow-xl bg-gray-900 border border-gray-800  px-4 py-8 h-full overflow-hidden rounded-2xl flex flex-col  items-center">
       <div className="w-44 h-44 mb-4">
       <Image height={200} width={200} blurDataURL={blurURL}  alt={"Best Software Solutions - NubitSoft | nubitsoft.com"} src={url} />
       </div>
       <div>
        <h1 className=" font-bold text-xl text-center text-white mb-4 relative">{otherTitle}</h1>
        <h1 className="font-bold text-xl text-center text-white mb-4 relative ">
         {title}
        </h1>
       </div>

        {/* Meaty part - Meteor effect */}
        <Meteors number={20} />
      </div>
    </div>
  );
}
export default ServicesCard