"use client";
import * as React from "react";
import { cn } from "@/lib/utils";
import { useMotionTemplate, useMotionValue, motion } from "framer-motion";

const Input = React.forwardRef(
  ({ className, type = "text", rows, cols, ...props }, ref) => {
    const radius = 100; // Hover effect radius
    const [visible, setVisible] = React.useState(false);

    let mouseX = useMotionValue(0);
    let mouseY = useMotionValue(0);

    function handleMouseMove({ currentTarget, clientX, clientY }) {
      let { left, top } = currentTarget.getBoundingClientRect();
      mouseX.set(clientX - left);
      mouseY.set(clientY - top);
    }

    return (
      <motion.div
        style={{
          background: useMotionTemplate`
        radial-gradient(
          ${visible ? radius + "px" : "0px"} circle at ${mouseX}px ${mouseY}px,
          var(--blue-500),
          transparent 80%
        )
      `,
        }}
        onMouseMove={handleMouseMove}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        className="p-[2px] rounded-lg transition duration-300 group/input"
      >
        {type === "textarea" ? (
          <textarea
            ref={ref}
            rows={rows || 5} // Default to 5 rows if not provided
            cols={cols || 50} // Default to 50 columns if not provided
            className={cn(
              `flex w-full border-none bg-zinc-800 text-white  rounded-md px-3 py-2 text-sm 
              placeholder:text-neutral-400 dark:placeholder-text-neutral-600
              focus-visible:outline-none focus-visible:ring-[2px] focus-visible:ring-neutral-600 disabled:cursor-not-allowed disabled:opacity-50
              shadow-[0px_0px_1px_1px_var(--neutral-700)]
              transition duration-400`,
              className
            )}
            {...props}
          ></textarea>
        ) : (
          <input
            type={type}
            className={cn(
              `flex h-10 w-full border-none bg-zinc-800 text-white  rounded-md px-3 py-2 text-sm 
              placeholder:text-neutral-400 dark:placeholder-text-neutral-600 
              focus-visible:ring-neutral-600 disabled:cursor-not-allowed disabled:opacity-50 
              shadow-[0px_0px_1px_1px_var(--neutral-700)] group-hover/input:shadow-none 
              transition duration-400 `,
              className
            )}
            ref={ref}
            {...props}
          />
        )}
      </motion.div>
    );
  }
);

Input.displayName = "Input";

export { Input };
