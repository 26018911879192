"use client";
import React from "react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { cn } from "@/lib/utils";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { errorNotify, successNotify } from "@/hooks/toast";

export function ContactForm() {
   const initialValues = {
    name: '',
    email: '',
    message: '', 
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Full Name is required')
      .min(3, 'Full Name must be at least 3 characters'),
      email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
      message: Yup.string()
        .required('Meassage is required')
        .min(10, 'Meassage is too short'),
     
  });
  const sendEmail = (values) => {
    emailjs.send(
      'service_dpbmkqo',
      'template_rs4v6mm',
      {
        to_name: 'NubitSoft Company',
        from_name: values.name, 
        message: values.message, 
        from_email: values.email, 
        reply_to: values.email,  
      },
      'ZWcR2BVxbB-neoaUD'
    ).then((result) => {
      console.log('Email sent successfully:', result.text);
      successNotify("Email sent successfully")
    }).catch((error) => {
      console.log('Failed to send email:', error.text);
      errorNotify()
    });
  };

  return (
    <div className="max-w-md w-full mx-auto rounded-none md:rounded-2xl md:py-12 shadow-input">
      <h2 className="font-bold text-4xl text-neutral-200">
        Contact Us
      </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema} 
        onSubmit={(values,{resetForm}) => {
          // console.log(values);
          sendEmail(values);
        
          resetForm()
        }}
      >
        {({ errors, touched }) => (
          <Form className=" ">
            <LabelInputContainer>
              <Label htmlFor="name">Name</Label>
              <Field
                id="name"
                name="name"
                placeholder="Your Name"
                type="text"
                as={Input} 
              />
              <ErrorMessage name="name" component="div" className="text-red-500 text-sm" />
            </LabelInputContainer>

            <LabelInputContainer className="mb-4">
              <Label htmlFor="email">Email Address</Label>
              <Field
                id="email"
                name="email"
                placeholder="projectmayhem@fc.com"
                type="email"
                as={Input}
              />
              <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
            </LabelInputContainer>

            <LabelInputContainer className="mb-8">
              <Label htmlFor="message">Message</Label>
              <Field
                id="message"
                name="message"
                placeholder="Your message..."
                as="textarea"
                rows={6}
                className="flex h-24 w-full border-none bg-zinc-800 text-white rounded-md px-3 py-2 text-sm 
                placeholder:text-neutral-400 dark:placeholder-text-neutral-600 
                focus-visible:ring-neutral-600 disabled:cursor-not-allowed disabled:opacity-50 
                shadow-[0px_0px_1px_1px_var(--neutral-700)] group-hover/input:shadow-none 
                transition duration-400" 
              />
              <ErrorMessage name="message" component="div" className="text-red-500 text-sm" />
            </LabelInputContainer>

            <button
              className="bg-gradient-to-br relative group/btn block bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
              type="submit"
            >
              Submit &rarr;
              <BottomGradient />
            </button>

            <div className="bg-gradient-to-r from-transparent via-neutral-700 to-transparent my-8 h-[1px] w-full" />
          </Form>
        )}
      </Formik>
        <ToastContainer/>
    </div>
  );
}

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const LabelInputContainer = ({ children, className }) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};
