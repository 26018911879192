"use client";
import React, { useState } from "react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { Formik, Form, Field, ErrorMessage, } from 'formik';
import * as Yup from 'yup';
import { AddBlog, AddNewBlog } from '@/API/response';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/navigation";
import { errorNotify, successNotify, warningNotify } from "@/hooks/toast";
import { Logout } from "@/GlobalRedux/UserSlice";

export function BlogForm() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const initialValues = {
    name: "",
    title: "",
    message: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required"),
    title: Yup.string()
      .required("Title is required"),
    message: Yup.string()
      .required("Blog Description is required"),

  });
  const { users } = useSelector((state) => state.user);
  const adminToken = users?.token
  const router = useRouter()
  const handleSubmit = async (values, { resetForm }) => {
    const route = '/blog/add'
    try {
      setLoading(true)
      const response = await AddNewBlog(route, values,adminToken)
      if(response.message == "In Valid Token"){
        errorNotify("Session expired. Please Login")
        dispatch(Logout())
        setLoading(false)
        router.push("/login")
        
      } 
      if(response.message =='Blog has been Added'){
        successNotify("Blogs Add Successful!")
        // router.push('/')
        resetForm();
        setLoading(false)
      }
     

    } catch (error) {
      console.log("error", error.response.data)
      errorNotify(error)
      setLoading(false)

    }
  }
  
  return (
    (<div
      className="max-w-md w-full mx-auto rounded-none md:rounded-2xl md:py-12 shadow-input  ">
      <span className="flex justify-between items-center">
        <h2 className="font-bold text-4xl text-center text-neutral-200">
          Add Blogs
        </h2>
      </span>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form className=" " >
          <LabelInputContainer className="mb-4">
            <Label htmlFor="name">Your Name</Label>
            <Field
              name="name"
              type="text"
              placeholder="John Deo"
              as={Input} />
            <ErrorMessage name="name" component="div" className="text-red-500 text-sm mt-1" />
          </LabelInputContainer>

          <LabelInputContainer className="mb-4">
            <Label htmlFor="title">Blog Title</Label>
            <Field
              name="title"
              placeholder="Blogs Title"
              type="title"
              as={Input}
            />
            <ErrorMessage name="title" component="div" className="text-red-500 text-sm mt-1" />
          </LabelInputContainer>
          <LabelInputContainer className="mb-4">
            <Label htmlFor="message">Blog Description</Label>
            <Field
              name="message"
              placeholder="Blogs Description"
              type="textarea"
              as="textarea"
              col='9'
              className="flex h-24 w-full border-none bg-zinc-800 text-white rounded-md px-3 py-2 text-sm 
                placeholder:text-neutral-400 dark:placeholder-text-neutral-600 
                focus-visible:ring-neutral-600 disabled:cursor-not-allowed disabled:opacity-50 
                shadow-[0px_0px_1px_1px_var(--neutral-700)] group-hover/input:shadow-none 
                transition duration-400"
            />
            <ErrorMessage name="message" component="div" className="text-red-500 text-sm mt-1" />
          </LabelInputContainer>
          <button
            className="bg-gradient-to-br relative group/btn  from-zinc-900 to-zinc-900  block bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
            disabled={loading}
            type="submit"
            >
            {loading ? (
          <span className="loader">loading...</span>
        ) : (
          <>
            Add Blogs &rarr;
            <BottomGradient />
          </>
        )}
          </button>
          <div
            className="bg-gradient-to-r from-transparent via-neutral-700 to-transparent my-8 h-[1px] w-full" />
        </Form>
      </Formik>
      <ToastContainer />
    </div>)
  );
}

const BottomGradient = () => {
  return (<>
    <span
      className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
    <span
      className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
  </>);
};

const LabelInputContainer = ({
  children,
  className
}) => {
  return (
    (<div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>)
  );
};
