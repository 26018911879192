"use client"
import { getAllBlogs } from "@/API/response";
import { fetchingStart, getCandidateSuccess } from "@/GlobalRedux/GetCareerDetails";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApplicantCards from "./ApplicantCard";
export const ApplicantCard = () => {
  const dispatch = useDispatch();
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const getCandidateDatails = async () => {
    try {
      const route = '/career/get'
      dispatch(fetchingStart())
      const response = await getAllBlogs(route);
      // console.log("response", response)
      dispatch(getCandidateSuccess(response?.data));
    } catch (error) {
      console.log("error", error.response.data);
    }
  };
  const { getDetail, isloader } = useSelector((state) => state.candidateDetail);
  // console.log("get candicate details ===>>>>", getDetail)
  const filteredCandidates = selectedDepartment
    ? getDetail.filter((data) => data.department === selectedDepartment)
    : getDetail;
    const sortedApplicant = filteredCandidates?.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    // console.log("applicant-->>>>",sortedApplicant)

  

  useEffect(() => {
    getCandidateDatails()
  }, [])
  // "Web Development", "Digital Marketing", "Sales", "Project Management","Accounts & Finance", "Others"
  return (
    <>
      <div className="">
        <label className="text-xl font-bold text-slate-300">Filter by Department: </label>
        <select
          value={selectedDepartment}
          onChange={(e) => setSelectedDepartment(e.target.value)}
          className="p-2 rounded border border-gray-400"
        >
          <option value="">All Departments</option>
          <option value="Web Development">Web Development</option>
          <option value="Digital Marketing">Digital Marketing</option>
          <option value="Sales">Sales</option>
          <option value="Project Management">Project Management</option>
          <option value="Accounts & Finance">Accounts & Finance</option>
          <option value="Others">Others</option>
        </select>
        <h1 className="text-zinc-200   p-5 rounded-md font-extrabold text-4xl tracking-wide text-center">
          {selectedDepartment}
        </h1>
      </div>
      <div className="grid grid-cols-1  md:grid-cols-2  lg:grid-cols-3  xl:grid-cols-4  max-w-screen-4xl  justify-items-center py-10  gap-6">

        {isloader ? (
          <div className="flex bg-gray-800 mx-auto  justify-center items-center text-center  w-screen h-screen">
            <div className="flex justify-center items-center py-10 ">
              <div className="animate-spin rounded-full h-20 w-20 border-b-2 border-blue-400"></div>
            </div>
          </div>
        ) :
          getDetail.length === 0 ? (<span className="flex mx-auto justify-center items-center text-center  w-screen h-screen"> <h2 className="text-zinc-500 py-10 xl:mr-40 bg-gray-200 p-5 rounded-md font-bold tracking-wide text-center">
            No Candidate Applicant Request
          </h2></span>) :
            sortedApplicant.length === 0 ? (<span className="flex mx-auto justify-center items-center text-center  w-screen h-screen"> <h2 className="text-zinc-500 py-10 xl:mr-40 bg-gray-200 p-5 rounded-md font-bold tracking-wide text-center">
              No Candidate Applicant Request
            </h2></span>) :
              <>

                {sortedApplicant.map((data, index) => (
                  <ApplicantCards
                    key={index}
                    applicantName={data.fullname}
                    applicantRole={data.department}
                    previousJob={data.previousJob}
                    applicantExp={data.experience}
                    applicantEmail={data.email}
                    applicantPhone={data.mobile}
                    applicantLinkedin={data.linkedin}
                    applicantDate={new Date(data.createdAt).toLocaleDateString("en-CA").split("-").reverse().join("-")}
                    id={data._id}
                  />
                ))}

              </>
         
        }

      </div>
    </>
  );
};
