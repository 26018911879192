"use client"
import React, { useRef } from 'react'
import { FeaturesSectionDemo } from './Cards'
import { BgLines } from './background-lines'
import { motion, useInView } from 'framer-motion'
export const AboutUs = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <div className="max-w-7xl mx-auto ">
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, delay: 0 }}
        className='flex flex-col items-start justify-center max-w-4xl py-40 px-10 gap-5 mx-auto'>
        <span className='text-2xl md:text-4xl font-bold text-white '>
          About Us
        </span>
        <span className='text-lg md:text-xl font-semibold text-white md:w-full   capitalize'>
        Nubit Software (Pvt) Ltd. stands as a trailblazing force within the Pakistani Software and Financial services sector, with its headquarters situated in the vibrant city of Karachi. Established in 2017, the company has swiftly risen to prominence, solidifying its position as a pivotal player in the ever-evolving landscape of the IT industry. Renowned for its commitment to innovation, Nubit Software (Pvt) Ltd. is at the forefront of technological advancements, delivering a diverse array of cutting-edge products and solutions that cater to the dynamic needs of its clientele across various industries. With a vast range of offerings spanning different sectors, including finance, healthcare, education, and more, the company showcases its versatility and adaptability. With a vision rooted in excellence and a mission to drive progress. Nubit Software (Pvt) Ltd continues to shape the future of the industry, seamlessly integrating software and financial services to redefine the standards of efficiency and performance across diverse fields.

        </span>
      </motion.div>
     
      <FeaturesSectionDemo />

      <motion.div
        ref={ref}
        initial={{ opacity: 0, x: -100 }} 
        animate={isInView ? { opacity: 1, x: 0 } : {}} 
        transition={{ duration: 0.5, delay: 0.1 }}  
        className='flex flex-col items-start justify-center max-w-4xl py-40 px-10 gap-5 mx-auto'>
        <span className='text-2xl md:text-xl font-bold text-gray-400 uppercase'>
          Why choose us
        </span>
        <span className='text-lg md:text-4xl font-semibold text-gray-300 md:w-2/3 '>
          Our experienced teams guide high-growth companies through the product development journey, from ideation to launch.
        </span>
      </motion.div>
      {/* <ImagesSliderDemo/> */}
      <div >
        <BgLines />
      </div>
    </div>
  )
}
