// InfiniteMovingCards component for project cards
"use client"
import Image from "next/image";
import { cn } from "@/lib/utils";
import Link from "next/link";
import React, { useEffect, useState } from "react";
// Infinite scrolling cards component
export const InfiniteMovingCards = ({items,
  direction = "right",
  speed = "normal",
  pauseOnHover = true,
  className}) => {
  const containerRef = React.useRef(null);
  const scrollerRef = React.useRef(null);

  useEffect(() => {
    addAnimation();
  }, []);
  const [start, setStart] = useState(false);
  function addAnimation() {
    if (containerRef.current && scrollerRef.current) {
      const scrollerContent = Array.from(scrollerRef.current.children);

      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        if (scrollerRef.current) {
          scrollerRef.current.appendChild(duplicatedItem);
        }
      });

      getDirection();
      getSpeed();
      setStart(true);
    }
  }
  const getDirection = () => {
    if (containerRef.current) {
      if (direction === "right") {
        containerRef.current.style.setProperty("--animation-direction", "forwards");
      } else {
        containerRef.current.style.setProperty("--animation-direction", "reverse");
      }
    }
  };
  const getSpeed = () => {
    if (containerRef.current) {
      if (speed === "slow") {
        containerRef.current.style.setProperty("--animation-duration", "80s");
      } else if (speed === "normal") {
        containerRef.current.style.setProperty("--animation-duration", "80s");
      } else {
        containerRef.current.style.setProperty("--animation-duration", "80s");
      }
    }
  };
  return (
    <div  ref={containerRef}
    className={cn(
      "scroller relative z-20 max-w-7xl overflow-hidden ",
      className
    )}>
      <div  ref={scrollerRef}
        className={cn(
          " flex  h-full min-w-full shrink-0 w-max flex-nowrap ",
          start && "animate-scroll ",
          pauseOnHover && "hover:[animation-play-state:paused]"
        )}>
        {/* Display the project cards */}
        {items.map((item, idx) => (
          <Card key={idx} item={item} />
        ))}
        {/* Duplicate the cards for seamless infinite scrolling */}
        {items.map((item, idx) => (
          <Card key={idx + items.length} item={item} />
        ))}
      </div>
    </div>
  );
};

// Card component to display individual project items
const Card = ({ item, }) => {
  return (
    <div className=" h-full my-5 w-96 m-6 bg-gray-800 rounded-lg   border border-gray-700 transition-transform duration-300 ease-in-out hover:scale-105">

     <div  className="h-60 ">
      <Image
            alt={"Best Software Solutions - NubitSoft | nubitsoft.com"}
            className={"h-full w-full object-fit overflow-hidden"}
            width="200"
            height="200"
            quality={100}
             loading="lazy"
            // priority
            src={item?.url}/>
     </div>
      <div className="flex h-20  items-center justify-between px-4 ">
      <h3 className="text-xl text-white font-semibold">{item?.title}</h3>
      <p className="text-sm text-gray-400">{item?.description}</p>
      {item?.href ? (
  <Link 
    href={item.href}
    className="text-blue-400 font-bold hover:underline mt-2 inline-block"
  >
    View Project
  </Link>
) : null}
      </div>
      
    </div>
  );
};
