"use client"
import { DeleteBlog, EditReviewOrBlog, getAllBlogs } from "@/API/response";
import { fetchingStart, getReviewsSuccess,deleteReview, updateReview } from "@/GlobalRedux/getReviewsSlice";
import { useInView, motion } from "framer-motion";
import Image from "next/image";
import React, { useEffect, useRef, useState } from "react";
import { useId } from "react";
import { useDispatch, useSelector } from "react-redux";
import FetchingLoader from '@/components/SkeletonLoader/FetchingLoader';
import { FaRegTrashCan } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import {Modal} from './modal'
import CustomModal from "@/hooks/confirmationModal";
import { useRouter } from "next/navigation";
import { Logout } from "@/GlobalRedux/UserSlice";
import { errorNotify } from "@/hooks/toast";

export function ReviewSection() {
  const { users } = useSelector((state) => state.user);
  const adminToken = users?.token
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [singleReview, setSingleReview ] = useState([])
  const [DeleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { getReviews, isloader } = useSelector((state) => state.reviews);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const router = useRouter();
  const isInView = useInView(ref, { once: true });

  // edit function open modal
  const handleOpenModal = (id) => {
    GetSingleReview(id)
    setIsModalOpen(true);
  };
  // Edit API Function get single API 
  const handleUpdateData =async (updatedData) => {
    const response = await EditReviewOrBlog(`/reviews/${singleReview?._id}`,adminToken,updatedData)
    if(response.message == "In Valid Token"){
      errorNotify("Session expired. Please Login")
      router.push("/login")
      dispatch(Logout())
    }
    if (response) {
      dispatch(updateReview(response.data)); 
      setSingleReview(updatedData);        
    }
    setSingleReview(updatedData);
  };
  // get single  review  API calling   
  const GetSingleReview = async(id) =>{
    const response = await getAllBlogs(`/reviews/${id}`)
    setSingleReview(response.data)
  }
  // close edit modal 
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // show more reviews function
  const [expandedAddresses, setExpandedAddresses] = useState([]);
  const toggleShowMore = (index) => {
    setExpandedAddresses((prev) => ({
      ...prev,
      [index]: !prev[index], 
    }));
  };
  
  // get all reviews API call
  const route = '/reviews';
  const GetReviews = async () => {
    try {
      dispatch(fetchingStart())
      const response = await getAllBlogs(route);
      dispatch(getReviewsSuccess(response.data));
    } catch (error) {
      console.log("error", error.response.data);
    }
  };


   // delete function open modal
   const handleOpenDelModal = (id) => {
    setSelectedId(id)
    setDeleteModal(true);
  };
  
  // DELETE  Blog API function
  const deleteReviewFunc = async() =>{
    const response = await DeleteBlog(`/reviews/delete/${selectedId}`,adminToken)
    if(response.message == "In Valid Token"){
      errorNotify("Session expired. Please Login")
      router.push("/login")
      dispatch(Logout())
    }
    dispatch(deleteReview(selectedId))
    closeDelModal()
  }
  
  // close DELETE modal
  const closeDelModal = () =>{
    setDeleteModal(false)
    setSelectedId(null);
  }


  useEffect(()=>{
    GetReviews()
  },[])
  return (
    (<div className="py-20 lg:py-40 bg-black ">
      <motion.div
        ref={ref}
        initial={{ opacity: 0, x: -100 }}
        animate={isInView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 1, delay: 0.2 }}
        className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 md:gap-2 max-w-7xl mx-auto "
        // className="flex max-w-7xl mx-auto  gap-4  overflow-x-scroll  h-full  "         
        >
          {isloader ? (
        <div className="grid grid-cols-1 justify-items-center w-full h-screen ">
          <FetchingLoader />
        </div>
      ) :
      getReviews.length=== 0 ? (<span className="grid  grid-cols-1  justify-items-center text-center mx-auto w-full h-full"> <h2 className="text-zinc-500 py-10 bg-gray-200 p-5 rounded-md font-bold tracking-wide text-center">
      No Reviews Avalaible
    </h2></span>):
      (
        getReviews.map((feature, index) => (
          <div
            key={feature._id} 
            className="relative  h-fit min-w-fit  bg-gradient-to-b from-neutral-900 to-neutral-950 p-6 rounded-3xl overflow-hidden">

            <Grid size={20} />
            {
                isloader ? <FetchingLoader/>: 
              <span className="flex justify-end gap-3">
             
              <span className={users?.data.role === "Admin" ? "hover:cursor-pointer hover:text-blue-500 flex " : "hidden"}>
                <FaRegEdit size={20} color="#fff" onClick={()=>handleOpenModal(feature._id)}/>
              </span>
              <span className={users?.data.role === "Admin" ? "hover:cursor-pointer  hover:text-red-500 flex " : "hidden"}>
                <FaRegTrashCan size={20} color="#fff"  onClick={()=>handleOpenDelModal(feature._id)}/>
              </span>
              </span>
              }
            <div className="flex justify-between w-full items-center ">

              <span >
                <Image
                  height="1000"
                  width="1000"
                  alt={"Best Software Solutions - NubitSoft | nubitsoft.com"}
                  src={feature.projectPic}
                  className="h-16 w-16 rounded-lg border-2 border-gray-700 object-contain"
                />
              </span>
              <span className="text-base font-bold text-white relative z-20 flex flex-col items-start text-center">
                <span className="text-base font-bold text-white  w-full">{feature?.companyName}</span>
                <span className="text-base font-bold text-white     w-full">{feature?.yourName}</span>
              </span>
            </div>
          
            <div className="flex mt-6 relative z-20 justify-between">
            <span className="text-base font-bold text-white     w-full">{feature?.softwareName}</span>

              {Array.from({ length: 5 }, (_, i) => (
                <span key={`${index}-${i}`}  
                  className={i < feature.rating ? "text-yellow-400" : "text-neutral-600"}>
                  ★
                </span>
              ))}

            </div>

            <p className="text-neutral-400 mt-4 text-base font-normal relative z-20">
              {expandedAddresses[index] ? feature?.description : `${feature?.description.slice(0, 245)}...`}
              {feature?.description.length > 30 && (
                <span onClick={() => toggleShowMore(index)} className="text-blue-500 cursor-pointer">
                  {expandedAddresses[index] ? " Show less" : " Show more"}
                </span>
              )}
            </p>
          </div>
        ))
      )}
      <Modal 
      onSubmit={handleUpdateData} data={singleReview} isOpen={isModalOpen} onClose={handleCloseModal} />
      <CustomModal openModal={DeleteModal}   onSubmit={() => deleteReviewFunc(selectedId)} onClose={closeDelModal}/>
      </motion.div>
      <ToastContainer/>

    </div>)
  );
}

export const Grid = ({ pattern, size }) => {
  const p = pattern ?? generateUniqueSquares(5);

  return (
    <div className="pointer-events-none absolute left-1/2 top-0 -ml-20 -mt-2 h-full w-full [mask-image:linear-gradient(white,transparent)]">
      <div className="absolute inset-0 bg-gradient-to-r [mask-image:radial-gradient(farthest-side_at_top,white,transparent)] dark:from-zinc-900/30 from-zinc-100/30 to-zinc-300/30 dark:to-zinc-900/30 opacity-100">
        <GridPattern
          width={size ?? 20}
          height={size ?? 20}
          x="-12"
          y="4"
          squares={p}
          className="absolute inset-0 h-full w-full mix-blend-overlay dark:fill-white/10 dark:stroke-white/10 stroke-black/10 fill-black/10"
        />
      </div>
    </div>
  );
};

export function GridPattern({ width, height, x, y, squares, ...props }) {
  const patternId = useId();

  return (
    <svg aria-hidden="true" {...props}>
      <defs>
        <pattern
          id={patternId}
          width={width}
          height={height}
          patternUnits="userSpaceOnUse"
          x={x}
          y={y}>
          <path d={`M.5 ${height}V.5H${width}`} fill="none" />
        </pattern>
      </defs>
      <rect width="100%" height="100%" strokeWidth={0} fill={`url(#${patternId})`} />
      {squares && (
        <svg x={x} y={y} className="overflow-visible">
          {squares.map(([x, y], index) => (
            <rect
              strokeWidth="0"
              key={`square-${index}-${x}-${y}`} 
              width={width + 1}
              height={height + 1}
              x={x * width}
              y={y * height} />
          ))}
        </svg>
      )}
    </svg>
  );
}

const generateUniqueSquares = (count) => {
  const squares = new Set();
  while (squares.size < count) {
    squares.add(
      [
        Math.floor(Math.random() * 4) + 7, 
        Math.floor(Math.random() * 6) + 1, 
      ].join('-')
    );
  }
  return Array.from(squares).map(square => square.split('-').map(Number));
};