"use client"
import { DeleteBlog, EditReviewOrBlog, getAllBlogs } from "@/API/response";
import { deleteBlog, getBlogSuccess,fetchingStart, updateBlog } from "@/GlobalRedux/getBlogSlice";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FetchingLoader from '@/components/SkeletonLoader/FetchingLoader';
import { FaRegTrashCan } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import { FaRegEdit } from "react-icons/fa";
import { BlogEditModal } from "./BlogEditModal";
import CustomModal from "@/hooks/confirmationModal";
import BlogModal from "../Blogs/blogModal";
import { useRouter } from "next/navigation";
import { Logout } from "@/GlobalRedux/UserSlice";
import { errorNotify } from "@/hooks/toast";

export const HoverEffect = ({ className }) => {
  const route = '/blog/get';
  const dispatch = useDispatch();
  const router = useRouter()
  const GetBlogs = async () => {
    try {
      dispatch(fetchingStart())
      const response = await getAllBlogs(route);
      // console.log("response", response);
      dispatch(getBlogSuccess(response.data));
    } catch (error) {
      console.log("error", error.response.data);
    }
  };

  const { getblogs, isloader } = useSelector((state) => state.blogs);
  const { users } = useSelector((state) => state.user);
  const adminToken = users?.token
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [DeleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const [singleBlog, setSingleBlog ] = useState([])
  
  // edit function open modal
  const handleOpenModal = (id) => {
    getSingleBlog(id)
    setIsModalOpen(true);
  };
  
  // Edit Blog API function
  const handleUpdateData =async (updatedData) => {
    const response = await EditReviewOrBlog(`/blog/update/${singleBlog?._id}`,adminToken,updatedData)
    if(response.message == "In Valid Token"){
      errorNotify("Session expired.  Please Login")
      router.push("/login")
      dispatch(Logout())
    } 
   else if (response) {
      dispatch(updateBlog(response.data)); 
      setSingleBlog(updatedData);        
    }
    setSingleBlog(updatedData);
  };
  // get single blog API function
  const getSingleBlog = async(id) =>{
    const response = await getAllBlogs(`/blog/${id}`)
    
    setSingleBlog(response.data)
  }
  // close  edit modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
 // delete modal or function work
  // delete function open modal
  const handleOpenDelModal = (id) => {
    setSelectedId(id)
    setDeleteModal(true);
  };
  
  // DELETE  Blog API function
  const deleteBlogFunc = async() =>{
    const response = await DeleteBlog(`/blog/delete/${selectedId}`,adminToken)
    if(response.message == "In Valid Token"){
      errorNotify("Session expired.  Please Login")
      router.push("/login")
      dispatch(Logout())
    }
    dispatch(deleteBlog(selectedId))
    closeDelModal()
  }
  // close  DELETE modal

  const closeDelModal = () =>{
    setDeleteModal(false)
    setSelectedId(null);
  }
  // see more option on blog 

  const [OpenBlog, setOpenBlog] = useState(false);
  const ShowBlogOpen = (id) => {
    // console.log("id --->>>",id)
    setOpenBlog(true)
    getSingleBlog(id)
  }
  const closeBlogModal = () =>{
    setOpenBlog(false)
  }
 
  // show blogs on load page 
  useEffect(() => {
    GetBlogs();
  }, []);

  return (
    <div className={cn("grid grid-cols-1 md:grid-cols-1  gap-4 lg:grid-cols-3 xl:grid-cols-3 py-10 overflow-x-hidden", className)}>
      {isloader ? (
        <div className="grid grid-cols-1 justify-items-center w-full h-screen ">
          <FetchingLoader />
        </div>
      ) :
      getblogs.length=== 0 ? (<span className="grid  grid-cols-1  justify-items-center text-center mx-auto w-full h-full"> <h2 className="text-zinc-500 py-10 bg-gray-200 p-5 rounded-md font-bold tracking-wide text-center">
      No Blogs Avalaible
    </h2></span>):
      (
        getblogs.map((item, idx) => (
          <Card key={idx} className={'w-full h-fit min-h-80'}>
            <span
            className={users?.data.role === "Admin" ? "justify-between items-center flex" : "flex justify-center items-center"}
            >
              <span>
                <CardTitle className={'capitalize truncate-title'}>{item.title}</CardTitle>
              </span>
              {
                isloader ? <FetchingLoader/>: 
                <>
              <span className="flex justify-end gap-3">
             
             <span className={users?.data.role === "Admin" ? "hover:cursor-pointer hover:text-blue-500 flex " : "hidden"}>
               <FaRegEdit size={20} color="#fff" onClick={()=>handleOpenModal(item._id)}/>
             </span>
             <span className={users?.data.role === "Admin" ? "hover:cursor-pointer  hover:text-red-500 flex " : "hidden"}>
               <FaRegTrashCan size={20} color="#fff"  onClick={()=>handleOpenDelModal(item._id)}/>
             </span>
             </span>
              </>
              }
            </span>
           
            <CardDescription className='truncate-description'>{item.message} </CardDescription> <span className="text-blue-600 text-lg font-bold cursor-pointer" onClick={()=>ShowBlogOpen(item._id)}>see more</span>
            <BlogEditModal 
              onSubmit={handleUpdateData} data={singleBlog} isOpen={isModalOpen} onClose={handleCloseModal} />
         <CustomModal openModal={DeleteModal}   onSubmit={() => deleteBlogFunc(selectedId)} onClose={closeDelModal}/>
        {singleBlog ? <BlogModal openModal={OpenBlog} data={singleBlog}   onClose={closeBlogModal}/> : <FetchingLoader/>}
          </Card>
          ))
        )}
        
      <ToastContainer/>
    </div>
  );
};

export const Card = ({ className, children }) => {
  return (
    <div
      className={cn(
        "rounded-2xl h-full w-full flex justify-center  p-4 overflow-hidden bg-black border border-white/[0.2] group-hover:border-slate-700 relative z-20",
        className
      )}
    >
      <div className="relative z-50">
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};

export const CardTitle = ({ className, children }) => {
  return (
    <h4 className={cn("text-zinc-100 font-bold tracking-wide mt-4", className)}>
      {children}
    </h4>
  );
};

export const CardDescription = ({ className, children }) => {
  return (
    <p className={cn("mt-6 text-zinc-300 tracking-wide leading-relaxed text-md", className)}>
      {children}
    </p>
  );
};
