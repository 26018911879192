import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Custom Success Toast
const successNotify = (message) =>
  toast.success(message, {
    position: "top-center",
    autoClose: 2000, // Set auto-close time in milliseconds
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: "✅", // Custom icon for success
    style: { backgroundColor: "#fff", color: "#000" }, 
  });

// Custom Error Toast
const errorNotify = (message) =>
  toast.error(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: "❌", // Custom icon for error
    style: { backgroundColor: "#fff", color: "#f44336" },
  });

// Custom Warning Toast
const warningNotify = (message) =>
  toast.warning(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: "⚠️", // Custom icon for warning
    style: { backgroundColor: "#fff", color: "#ff9800" },
  });

function showNotify(message) {
  if (message === "user not found") {
    errorNotify("User not found");
  } else if (message === "Invalid credentials") {
    warningNotify("Invalid credentials");
  } else if (message === "OK") {
    successNotify("Login successful");
  } else {
    errorNotify("Something went wrong");
  }
}

export { successNotify, errorNotify, warningNotify, showNotify };
