import { cn } from "@/lib/utils";

import Image from "next/image";
export function FeaturesSectionDemo() {
  const features = [
    {
      title: "National top 50 consulting firms",
      description:
        "A top-tier consulting firm with a proven track record of success.",
      url: 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575830/worldwide_pv0ovz.webp',
    },
    {
      title: "99.9% client satisfaction",
      description:
        "Building strong relationships with our clients is our top priority.",
      url: 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575830/satisfy_b0zeiz.webp',
    },
    {
      title: "Over 100+ Business Cases",
      description:
        "Proven success in helping businesses achieve their goals.",
      url: 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575804/business2_lg2v5k.webp',
    },
    {
      title: "Operated in 6+ Countries",
      description: "Global reach and expertise to help businesses succeed anywhere in the world.",
      url: 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575804/foriegn1_pbtzid.webp',
    },

  ];
  return (

    (<div
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 relative z-10 py-10 max-w-7xl mx-auto">
      {features.map((feature, index) => (
        <Feature key={feature.title} {...feature} index={index} />
      ))}
    </div>)
  );
}

const Feature = ({
  title,
  description,
  url,
  index,
  blurURL

}) => {
  return (
    (<div
      className={cn(
        "flex flex-col lg:border-r  py-10 relative group/feature dark:border-neutral-800",
        (index === 0 || index === 4) && "lg:border-l dark:border-neutral-800",
        index < 4 && "lg:border-b dark:border-neutral-800"
      )}>
      {index < 4 && (
        <div
          className="opacity-0 group-hover/feature:opacity-80 transition duration-200 absolute inset-0 h-full w-full bg-gradient-to-t from-neutral-100 dark:from-neutral-800 to-transparent pointer-events-none" />
      )}

      <div
        className="mb-4 relative h-full z-10 px-10 text-neutral-600 dark:text-neutral-400">
        <Image
          src={url}
          alt={"Best software solutions by Nubitsoft - Custom Web & Mobile Development"}
          height="500"
          width="500"
          // placeholder="blur"
          blurDataURL={blurURL}
          className="rounded-lg mb-10 h-44 object-cover" />
      </div>
      <div className="text-lg font-bold mb-2 relative z-10 px-10">
        <div
          className="absolute left-0 inset-y-0 h-6 group-hover/feature:h-8 w-1 rounded-tr-full rounded-br-full bg-neutral-700 group-hover/feature:bg-blue-500 transition-all duration-200 origin-center" />
        <span
          className="group-hover/feature:translate-x-2 transition duration-200 inline-block text-neutral-100">
          {title}
        </span>
      </div>
      <p
        className="text-sm text-neutral-300 max-w-xs relative z-10 px-10">
        {description}
      </p>
    </div>)
  );
};
