"use client";
import React from "react";

const CustomModal = ({ openModal, onClose, onSubmit }) => {
  return (
    <>
      {openModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-96">
            {/* Modal Header */}
            <div className="px-4 py-2 text-lg font-semibold text-white bg-red-500 rounded-t-lg">
              Confirm Delete
            </div>

            {/* Modal Body */}
            <div className="p-4 text-gray-800">
              <p>Are you sure you want to delete this item ?</p>
            </div>

            {/* Modal Footer */}
            <div className="flex items-center justify-end gap-4 p-4 border-t">
              <button
                className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600"
                onClick={onSubmit}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomModal;
