"use client";
import React, { useRef, useState } from "react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { Formik, Form, Field, ErrorMessage, } from 'formik';
import * as Yup from 'yup';
import { AddReviewOrCareerForm } from '@/API/response';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { useRouter } from "next/navigation";
import { errorNotify, successNotify, warningNotify } from "@/hooks/toast";
import { Logout } from "@/GlobalRedux/UserSlice";

export function ReviewForm() {
  const [loading, setLoading] = useState(false);
  const initialValues = {
    yourName: "",
    companyName: "",
    softwareName: "",
    description: "",
    rating: "",
  };
  const validationSchema = Yup.object({
    yourName: Yup.string()
      .required("Name is required"),
      companyName: Yup.string()
      .required("company is required"),
      softwareName: Yup.string()
      .required("software is required"),
      rating: Yup.string()
    .nullable() // Allows the field to be optional
    .test("rating-range", "Rating must be between 1 and 5", (value) => {
      // Only check if a value is provided
      return !value || (parseInt(value) >= 1 && parseInt(value) <= 5);
    }),
      description: Yup.string()
      .required(" Description is required"),

  });
  const refFile = useRef()
  const handleFileChange = (e) => {
    const maxFileSize = 5 * 1024 * 1024; // 5MB limit
    const selectedFiles = Array.from(e.target.files);
    
    const validFiles = selectedFiles.filter(file => file.size <= maxFileSize);
    const largeFiles = selectedFiles.filter(file => file.size > maxFileSize);
    if (largeFiles.length > 0) {
      alert(`File size must not exceed 5MB. These files are too large: ${largeFiles.map(file => file.name).join(", ")}`);
      refFile.current.value = "";
    }
  };

  const { users } = useSelector((state) => state.user);
  const adminToken = users?.token
  const router = useRouter()
  const handleSubmit = async (values, { resetForm }) => {
    const route = '/reviews/add'
    // console.log("values--->>>",values)
    
    const data = new FormData()
    data.append("yourName",values.yourName)
    data.append("companyName",values.companyName)
    data.append("softwareName",values.softwareName)
    data.append("rating",values.rating)
    data.append("description",values.description)
    data.append("file",refFile.current.files[0])
    // console.log("data--->>>",data)
  
    try {
      setLoading(true)
      const response = await AddReviewOrCareerForm(route, data,adminToken)
      if(response.message == "In Valid Token"){
        errorNotify("Session expired.  Please Login")
        setLoading(false)
        router.push("/login")
        dispatch(Logout())
      }
      if(response.message =='Review Add Successfully'){
        successNotify("Review Add Successfully")
        // router.push('/')
        resetForm();
        setLoading(false)
        if (refFile.current) {
          refFile.current.value = ''; 
        }
      }
      

    } catch (error) {
      if (refFile.current) {
        refFile.current.value = ''; 
      }
      console.log("error", error.response.data)
      errorNotify(error)
      setLoading(false)

    }
  }

  return (
    (<div
      className="max-w-md w-full mx-auto rounded-none md:rounded-2xl md:py-6 shadow-input  ">
      <span className="flex justify-between items-center">
        <h2 className="font-bold text-4xl text-center text-neutral-200">
          Add Reviews
        </h2>
      </span>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form className=" " >
          <LabelInputContainer className="mb-2">
            <Label htmlFor="name"> Name</Label>
            <Field
              name="yourName"
              type="text"
              placeholder="John Deo"
              as={Input} />
            <ErrorMessage name="yourName" component="div" className="text-red-500 text-sm mt-1" />
          </LabelInputContainer>

          <LabelInputContainer className="mb-2">
            <Label htmlFor="companyName">Company</Label>
            <Field
              name="companyName"
              placeholder="Company Name"
              type="text"
              as={Input}
            />
            <ErrorMessage name="companyName" component="div" className="text-red-500 text-sm mt-1" />
          </LabelInputContainer>
          <LabelInputContainer className="mb-2">
            <Label htmlFor="softwareName">Software</Label>
            <Field
              name="softwareName"
              placeholder="Software Name"
              type="text"
              as={Input}
            />
            <ErrorMessage name="softwareName" component="div" className="text-red-500 text-sm mt-1" />
          </LabelInputContainer>
          <LabelInputContainer className="mb-2">
            <Label htmlFor="rating">Rating *(Optional)</Label>
            <Field
              name="rating"
              placeholder="Select Number  In 1 to 5 "
              type="number"
              as={Input}
            />
            <ErrorMessage name="rating" component="div" className="text-red-500 text-sm mt-1" />
          </LabelInputContainer>
          <LabelInputContainer className="mb-2">
            <Label htmlFor="description">Review Description</Label>
            <Field
              name="description"
              placeholder="Review Description"
              type="textarea"
              as="textarea"
              col='9'
              className="flex h-24 w-full border-none bg-zinc-800 text-white rounded-md px-3 py-2 text-sm 
                placeholder:text-neutral-400 dark:placeholder-text-neutral-600 
                focus-visible:ring-neutral-600 disabled:cursor-not-allowed disabled:opacity-50 
                shadow-[0px_0px_1px_1px_var(--neutral-700)] group-hover/input:shadow-none 
                transition duration-400"
            />
            <ErrorMessage name="description" component="div" className="text-red-500 text-sm mt-1" />
          </LabelInputContainer>
           
            <div className="mb-2">
              <LabelInputContainer htmlFor="image">Add Image</LabelInputContainer>
              <input
                // name='image'
                ref={refFile}
                accept="image/*"
                onChange={handleFileChange}
                type="file"
                className="flex  h-full w-full opacity-1 cursor-pointer border-none bg-zinc-800 text-white rounded-md px-3 py-2 text-sm"
              />
            </div>
          <button
            className="bg-gradient-to-br mb-4 relative group/btn  from-zinc-900 to-zinc-900  block bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
            disabled={loading}
            type="submit"
          >
            {loading ? (
              <span className="loader">loading...</span>
            ) : (
              <>
                Add Reviews &rarr;
                <BottomGradient />
              </>
            )}
          </button>
          <div
            className="bg-gradient-to-r from-transparent via-neutral-700 to-transparent my-8 h-[1px] w-full" />
      <ToastContainer />
        </Form>
      </Formik>
    </div>)
  );
}

const BottomGradient = () => {
  return (<>
    <span
      className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
    <span
      className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
  </>);
};

const LabelInputContainer = ({
  children,
  className
}) => {
  return (
    (<div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>)
  );
};
