"use client";

import React from "react";
import { InfiniteMovingCards } from "@/components/ui/office-pic";
const OfficePic2 = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575816/OfficePic2_j59rdt.jpg'
const OfficePic3 = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575816/OfficePic3_sahi2q.jpg'
const OfficePic4 = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575821/OfficePic8_a4xq7h.jpg'
const OfficePic5 = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575820/OfficePic5_michzo.jpg'
const OfficePic6 = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575821/OfficePic7_klks9r.jpg'
const OfficePic7 = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575820/OfficePic6_dh25ff.jpg'
const OfficePic8 = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575820/OfficePic4_j85hwx.jpg'
const OfficePic10 = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575822/officePic10_ecgzl0.jpg'
const OfficePic11 = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575825/officePic11_wlxmdo.jpg'
const OfficePic12 = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575825/officePic12_tv42pz.jpg'
 function OfficePic() {
  return (
    <div className="h-[40rem]  flex flex-col antialiased bg-black items-center justify-center relative overflow-hidden">
      <InfiniteMovingCards
        items={Images}
        direction="right"
        speed="slow"
        
      />
    </div>
  )
}
export default OfficePic
const Images = [
  
  {
    url: OfficePic2,

  },
  {
    url: OfficePic3,

  },
  {
    url: OfficePic8,

  },
  {
    url: OfficePic5,

  },
  {
    url: OfficePic7,

  },
  {
    url: OfficePic6,

  },
  {
    url: OfficePic4,

  },
  {
    url: OfficePic10,

  },
  {
    url: OfficePic11,

  },
  {
    url: OfficePic12,

  },
];
