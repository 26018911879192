// Projects component
"use client";
import React from "react";
import { InfiniteMovingCards } from "@/components/ui/infinite-moving-cards";

 function Projects() {
  return (
    <div id="projects" className="h-full py-32 md:py-40  flex flex-col antialiased bg-black items-center justify-center overflow-hidden">
      <h1 className="text-4xl md:text-6xl bg-[#020617] text-white py-10 font-semibold text-center">
      Projects
      </h1>
      <InfiniteMovingCards items={projectItems} />
    </div>
  );
}
export default Projects
const projectItems = [
  {
    title: "Forexter",
    url:'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575826/project1_qit63s.png',
    link: "https://forexter.pk/",
    href: "https://forexter.pk/",
  },
  {
    title: "Cyltrax",
    url:'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575826/project2_nyc8np.png',
    link: "https://cyltrax.com/",
    href: "https://cyltrax.com/",
  },
  {
    title: "Trade Hub",
    url:'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575826/project3_ylvdqd.png',
    link: "https://trade-hub.com.pk/",
    href: "https://trade-hub.com.pk/",
  },
  {
    title: "Hedge Play",
    url:'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575826/project4_gaj94x.png',
    link: "https://trade-hub.com.pk/",
    href: "https://trade-hub.com.pk/",
  },
 
  {
    title: "SEBO",
    url:'https://res.cloudinary.com/dcwai4hwc/image/upload/v1731396765/sebo_bdb6pg.jpg',
    //  link: "https://trade-hub.com.pk/",
    //  href: "https://trade-hub.com.pk/",
  },
  {
    title: "NUREADY",
    url:'https://res.cloudinary.com/dcwai4hwc/image/upload/v1731498163/1_glbeyc.png',
    //  link: "https://trade-hub.com.pk/",
    //  href: "https://trade-hub.com.pk/",
  },
  {
    title: "ERP",
    url:'https://res.cloudinary.com/dcwai4hwc/image/upload/v1731567203/erp_y033b2.png',
    //  link: "https://trade-hub.com.pk/",
    //  href: "https://trade-hub.com.pk/",
  },
  // Add more project items as needed
];
