"use client";
import React from "react";
import { PinContainer } from "@/components/ui/3d-pin";
import Image from "next/image";
import { cn } from "@/lib/utils";
const PinCard = ({ title, url, projectName, imageURL }) => {
  return (
    <div className="h-[20rem] w-full flex items-center justify-center ">
      <PinContainer
        title={title}
        href={url}
      >
        <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[15rem] sm:w-[20rem] h-[15rem] ">
          <h1 className="max-w-xs text-xl !pb-2 !m-0 font-bold   text-slate-100">
            {projectName}
          </h1>

          <div className="flex  w-full rounded-lg bg-gray-400 my-6" >
            <Image
            alt={"Best Software Solutions | NubitSoft"}
              className={cn("h-full  w-60 sm:w-full object-fit scale-[1.15]")}
              width="800"
              height="800"
              src={imageURL}
            />
          </div>
        </div>
      </PinContainer>
    </div>
  )
}

export default PinCard