"use client"
import React from 'react'
import { motion,  } from "framer-motion";
import PinCard from './PinCard';
import Image from 'next/image';
const backgroundImage = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1730458139/JDRUUJ_gtjuef.jpg'

const showPortfolio = () => {
  return (
    <>
    <motion.div className=" bg-slate-300 h-[70vh] w-full overflow-hidden">
          <Image
            src={backgroundImage}
            alt="Creative Portfolio Background - Showcasing Projects and Designs"
            height={800}
            width={800}
            // priority
            className="brightness-90  w-full  h-full overflow-hidden"
          />
        </motion.div>

       <h3 className='text-4xl bg-[#020617] text-white md:text-5xl py-5 font-semibold text-center'>
       Discover Our Software Solutions for Real-World Problems
        </h3>
      <motion.div
         className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3  w-full h-full  text-center   "
        >
          <PinCard title={'trade-hub.com.pk'} projectName={'Trade Hub'} url={'https://trade-hub.com.pk/'} imageURL={'https://res.cloudinary.com/dcwai4hwc/image/upload/v1730455640/Tradehubportfolio_jdrxxn.png'}/>
          <PinCard title={'forexter.pk'} projectName={'Forexter'} url={'https://forexter.pk/'} imageURL={'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575826/project1_qit63s.png'}/>
          <PinCard title={'amlinsight.pk'} projectName={'Anti-Money Laundering'} url={'/'} imageURL={'https://res.cloudinary.com/dcwai4hwc/image/upload/v1730455604/amlinsight2_rc9y1g.png'}/>
          <PinCard title={'cyltrax.com'} projectName={'Cyltrax'} url={'https://cyltrax.com/'} imageURL={'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575826/project2_nyc8np.png'}/>
          <PinCard title={'fyslonline.com'} projectName={'Stock Brokage'} url={'https://fyslonline.com/'} imageURL={'https://res.cloudinary.com/dcwai4hwc/image/upload/v1730455640/Stockweb_es0uh4.png'}/>
          <PinCard title={'nubitlink.com'} projectName={'NubitLink'} url={'https://nubitlink.com/'} imageURL={'https://res.cloudinary.com/dcwai4hwc/image/upload/v1730455639/nubitlink_fcmagl.png'}/>
        
        </motion.div>
    </>
  )
}

export default showPortfolio