"use client"
import Link from 'next/link'
import React, { useState } from 'react'
import { FaRegTrashAlt } from "react-icons/fa";
import DeleteModal from './deleteModal';
import { DeleteApplicant } from '@/API/response';
import { useDispatch } from 'react-redux';
import { deleteCandidate } from '@/GlobalRedux/GetCareerDetails';
import Image from 'next/image';
const ApplicantCards = ({ applicantName, applicantRole, previousJob, applicantExp, applicantEmail, applicantPhone, id, applicantLinkedin, key, applicantDate }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [appID, setAppID] = useState(null)
    const dispatch = useDispatch()
    const openModal  = (id)=>{
    setAppID(id)
    setIsOpen(true)
    }
    const submitModal = async () =>{
        setIsOpen(false);
        try {
            const response = await DeleteApplicant(`/career/delete/${appID}`)
            dispatch(deleteCandidate(appID))
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <div key={key} className="w-80 h-auto mx-auto  bg-white rounded-xl shadow-md overflow-hidden border border-gray-200 p-4 relative">
                {/* Header Design */}
                <div className="absolute top-0 left-0 w-full h-16 bg-[#135C95] rounded-t-xl"></div>
                {/* <Link > */}
                <button onClick={() => openModal(id)} className="absolute top-2 right-2 p-1 bg-white text-red-500 rounded-full hover:text-red-700">
                    <FaRegTrashAlt className="w-5 h-5" />
                </button>
                {/* </Link> */}
                {/* Profile Image */}
                <div className="relative flex justify-center mt-10">
                    <div className="w-24 h-24 border-4 border-gray-200 rounded-full overflow-hidden">
                        <Image
                            src="https://media.istockphoto.com/id/1255163297/vector/user-profile-icon-vector-avatar-portrait-symbol-flat-shape-person-sign-logo-black-silhouette.jpg?s=612x612&w=0&k=20&c=p6azyhUBIcWx6-aXVRPUTveaVqbTA2bNXpBoGQjEB68="
                            alt="Profile Picture"
                              width={96}
                              height={96}
                            className="object-cover "
                        />
                    </div>
                </div>

                {/* Name & Title */}
                <div className="text-center ">
                    <h2 className="text-xl capitalize font-bold text-blue-700">{applicantName}</h2>
                    <p className="text-gray-500 text-md">{applicantRole}</p>
                </div>

                {/* Details */}
                <div className="mt-4 p-4 text-gray-700 text-sm">
                    <p><span className="font-semibold">Previous Job</span> : {previousJob}</p>
                    <p><span className="font-semibold">Experience </span> : {applicantExp}</p>
                    <p><span className="font-semibold">Email</span>: {applicantEmail}</p>
                    <p><span className="font-semibold">Phone</span>: {applicantPhone}</p>
                    <p><span className="font-semibold">Linkedin</span>: <Link href={`${applicantLinkedin}`} className='text-blue-600 hover:underline '>linkedin</Link></p>
                    <p><span className="font-semibold">Applying Date</span>: {applicantDate}</p>
                </div>
                <div className='flex justify-end '>
                    <Link href={`/showcareerform/${id}`}>
                        <button className=' p-2 bg-slate-400 text-black rounded-lg capitalize font-semibold  hover:bg-slate-800 hover:text-white'>see more</button>
                    </Link>
                </div>

            </div>
            <DeleteModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onConfirm={submitModal}
            />
        </>

    )
}

export default ApplicantCards