import React from 'react'

const FetchingLoader = () => {
    return (
        <div className='min-h-screen w-full  flex items-center justify-center'>
        <div className='loader'>
          <div className='circle'></div>
        </div>
      </div>
    )
}

export default FetchingLoader

