import { Field } from 'formik';
import React from 'react';

const Fields = ({ name, type, placeholder, options }) => {

  if (type === 'select') {
    return (
      <Field
        name={name}
        as="select"
        className="flex h-10 w-full border-none bg-zinc-800 text-white rounded-md px-3 py-2 text-sm 
                placeholder:text-neutral-400 dark:placeholder-text-neutral-600 
                focus-visible:ring-neutral-600 disabled:cursor-not-allowed disabled:opacity-50 
                shadow-[0px_0px_1px_1px_var(--neutral-700)] group-hover/input:shadow-none 
                transition duration-400"
      >
        <option value="">{placeholder}</option>
        {options && options.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))}
      </Field>
    );
  }
  return (
    <Field
      name={name}
      type={type}
      className="flex h-10 w-full border-none bg-zinc-800 text-white rounded-md px-3 py-2 text-sm 
                placeholder:text-neutral-400 dark:placeholder-text-neutral-600 
                focus-visible:ring-neutral-600 disabled:cursor-not-allowed disabled:opacity-50 
                shadow-[0px_0px_1px_1px_var(--neutral-700)] group-hover/input:shadow-none 
                transition duration-400"
      placeholder={placeholder}
    />
  );
};

export default Fields;
