"use client";
import React, { useRef, useEffect, useState } from 'react';
import { motion, useInView } from 'framer-motion';

export const AboutSkeleton = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <div className="w-full pt-28 bg-black h-full">
      <div className="max-w-7xl mx-auto">
        {/* About Us Section Skeleton */}
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0 }}
          className="flex flex-col items-start justify-center max-w-4xl py-40 px-10 gap-5 mx-auto"
        >
          <div className="skeleton-text w-1/4 h-8 bg-gray-700" />
          <div className="skeleton-text w-2/3 h-6 bg-gray-600" />
        </motion.div>

        {/* Features Section Skeleton */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 py-10 max-w-7xl mx-auto">
          {[...Array(4)].map((_, index) => (
            <div key={index} className="flex flex-col py-10 relative group">
              <div className="skeleton-box h-44 w-full bg-gray-700 mb-4 rounded-lg"></div>
              <div className="skeleton-text w-2/3 h-6 bg-gray-600 mb-2"></div>
              <div className="skeleton-text w-full h-4 bg-gray-500"></div>
            </div>
          ))}
        </div>

        {/* Why Choose Us Section Skeleton */}
        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: -100 }}
          animate={isInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 0.5, delay: 0.1 }}
          className="flex flex-col items-start justify-center max-w-4xl py-40 px-10 gap-5 mx-auto"
        >
          <div className="skeleton-text w-1/4 h-8 bg-gray-700" />
          <div className="skeleton-text w-2/3 h-6 bg-gray-600" />
        </motion.div>

        {/* Image Slider Section Skeleton */}
        <div className="relative h-[40rem] bg-gray-800 flex justify-center items-center overflow-hidden">
          <div className="skeleton-box w-full h-full bg-gray-700"></div>
        </div>

        {/* Background Lines Section Skeleton */}
        <div className="w-full h-[600px] bg-gray-800 flex justify-center items-center flex-col">
          <div className="skeleton-text w-2/3 h-8 bg-gray-700 mb-6"></div>
          <button className="skeleton-box w-36 h-10 bg-gray-700"></button>
        </div>
      </div>
    </div>
  );
};

