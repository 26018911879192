"use client";
export function SkeletonLoader() {
  return (
    <div className="max-w-full animate-pulse space-y-4 p-4">
      {/* Video Section Skeleton */}
      <div className="h-screen bg-gray-700 rounded-lg"></div>

      {/* Projects Section Skeleton */}
      <h1 className="mb-4 h-8 w-56 rounded-full bg-gray-700"></h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {Array.from({ length: 4 }).map((_, index) => (
          <div key={index} className="h-40 bg-gray-700 rounded-lg"></div>
        ))}
      </div>

      {/* Services Section Skeleton */}
      <h1 className="mb-4 h-8 w-56 rounded-full bg-gray-700"></h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {Array.from({ length: 4 }).map((_, index) => (
          <div key={index} className="h-40 bg-gray-700 rounded-lg"></div>
        ))}
      </div>

      {/* CEO & Director Section Skeleton */}
      <h1 className="mb-4 h-8 w-56 rounded-full bg-gray-700"></h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {Array.from({ length: 3 }).map((_, index) => (
          <div key={index} className="h-60 bg-gray-700 rounded-lg"></div>
        ))}
      </div>
    </div>
  );
}
