export const CareerFormFields = [
    {
      section: "Personal Information",
      fields: [
        {
          id: "fullname",
          label: "Full Name",
          type: "text",
          placeholder: "Enter your Full Name",
        },
        
        {
          id: "email",
          label: "Email",
          type: "email",
          placeholder: "Enter your Email",
        },
        
        {
          id: "mobile",
          label: "Mobile",
          type: "number",
          placeholder: "Enter your Mobile Number",
        },

        {
          id: "city",
          label: "City",
          type: "text",
          placeholder: "Enter your City",
        },
        {
            id: "address",
            label: "Address",
            type: "text",
            placeholder: "Enter your Address",
          },
          {
            id: "department",
            label: "Department",
            type: "select",
            placeholder: "Select Department",
            options: ["Web Development", "Digital Marketing", "Sales", "Project Management","Accounts & Finance", "Others"],
          },
          {
            id: "experience",
            label: "Experience",
            type: "number",
            placeholder: "Enter your Years of Experience",
          },
          {
            id: "previousJob",
            label: "Previous Job  *(optional)",
            type: "text",
            placeholder: "Enter your Previous Job",
          },
          
          {
            id: "foundJobFrom",
            label: "How did you find out this job?",
            type: "select",
            placeholder: "Select source",
            options: ["LinkedIn", "Job Portal" , "Other"],
          },
          {
            id: "linkedin",
            label: "LinkedIn Profile ",
            type: "text",
            placeholder: "Share your LinkedIn URL ",
          },
      ],
    },
  ];
  