"use client";
import React, { useState } from "react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import Image from "next/image";
import Link from "next/link";
import { Formik, Form,Field, ErrorMessage, } from 'formik';
import * as Yup from 'yup';
import { useRouter } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import {   LoginSuccess } from "@/GlobalRedux/UserSlice";
import { VscEye,VscEyeClosed  } from "react-icons/vsc";
import { ToastContainer, toast } from 'react-toastify';
import { AddNewBlog } from "@/API/response";
import { errorNotify, successNotify } from "@/hooks/toast";

export function LoginForm() {
  const dispatch = useDispatch()
  const NubitLogo = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575815/NubitLogogif_v1wzyu.gif'
  const [showPassword, setShowPassword] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const router = useRouter()
  const initialValues = {
    email:"",
    password:""
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address") 
      .required("Email is required"), 
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"), 
  });
  const route = "/user/login"
  const handleSubmit = async(values,{resetForm}) => {

        try {
          setIsLoader(true)
          const response =await AddNewBlog(route,values)
          // console.log("response --->>>>>>",response)
          setIsLoader(false)
          if(response?.data?.role == "Admin" && response.status ){
            dispatch(LoginSuccess(response))
            resetForm()
            successNotify(response?.message)
            router.push("/Addblogs")
            setIsLoader(false)
          }else if (response?.data?.role == "Client" ||  response?.data?.role == "HR" && response.status ){
            router.push(`https://nutrack.nubitsoft.com/login?token=${response?.token}`)
            // router.push(`https://nutrackbabu.vercel.app/login?token=${response?.token}`)
            successNotify(response?.message)
            setIsLoader(false)
          }
          else{
            errorNotify(response?.message)
            setIsLoader(false)

          }
       
        } catch (error) {
          setIsLoader(false)
          console.log("error",error.response.data)
          errorNotify(response?.message || "SomeThing Wrong")
        }
  }
 
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    (<div
      className="max-w-md w-full mx-auto rounded-none md:rounded-2xl md:py-12 shadow-input  ">
      <div className="flex justify-between items-center">
        <span>
          <h2 className="font-bold text-4xl text-neutral-200">
            Login
          </h2>
        </span>
        <Link href={'http://nizamuddintai-001-site31.ctempurl.com/'}>
          <Image height={100} width={100} alt="Nubit Login - Secure Access to Your Account" src={NubitLogo} />
        </Link>

      </div>
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
      <Form className=" " >
        <LabelInputContainer className="mb-4">
          <Label htmlFor="email">Email Address</Label>
          <Field 
              name="email"
              type="email"
              placeholder="Exmaple@gmail.com"
              className=""
              as={Input}  />
              <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
        </LabelInputContainer>
        <LabelInputContainer className="mb-8">
      <Label htmlFor="password">Password</Label>

      <div className="relative">
        <Field
          name="password"
          placeholder="••••••••"
          type={showPassword ? 'text' : 'password'} 
          as={Input}
          className="" 
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute -inset-y-2  right-3  py-5 flex items-center text-gray-300"
          // className="absolute top-1/2 transform -translate-y-1/2 right-3 py-5 flex items-center text-gray-300"
        >
          {showPassword ? (
            <VscEye className="h-5 w-5" aria-hidden="true" />
          ) : (
            <VscEyeClosed  className="h-5 w-5" aria-hidden="true" />
          )}
        </button>

      </div>
        <ErrorMessage name="password" component="div" className="text-red-500 text-sm mb-1" />
    </LabelInputContainer>
    <button
            className="bg-gradient-to-br relative group/btn  from-zinc-900 to-zinc-900  block bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
            type="submit"
            >
              {
                isLoader ? 
              <div className="flex justify-center items-center">
            <svg
            aria-hidden="true"
          className="animate-spin h-5 w-5 mr-3 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8h8a8 8 0 11-16 0z"
          ></path>
            </svg>
             Loading...
               </div> : "Login" 
              }

              
           <BottomGradient />
          </button>         
        <div
          className="bg-gradient-to-r from-transparent via-neutral-700 to-transparent my-8 h-[1px] w-full" />
      </Form>
      </Formik>
      <ToastContainer/>
    </div>)
  );
}

const BottomGradient = () => {
  return (<>
    <span
      className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
    <span
      className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
  </>);
};

const LabelInputContainer = ({
  children,
  className
}) => {
  return (
    (<div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>)
  );
};
